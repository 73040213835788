.footer {
    background-color:rgb(21, 137, 179);
    height: auto;
    padding: 0px 0 0px 0px;
}

.footer_top {
    color: #fff !important;
    padding: 0;
}

.footer_copyrigth {
    background-color: rgb(23, 123, 159);
    height: 60px;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    font-weight: 300;
    padding: 20px 0;
}

.center_title {
    margin: 0;
    font-size: 28px;
    color: #fff;
    text-align: left;
}

.center_content {
    list-style: none;
    text-align: left;
    color: #fff;
    padding: 10px 0 0;
}

.center_content li {
    margin-bottom: 18px;
}

.center_content li a {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

.center_content li a:hover {
    text-decoration: underline;
}

.footer_info {
    color: #fff;
}

.footer_main {
    padding: 100px 10px 60px 30px;
}