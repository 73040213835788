/* Styles for Header component */
.navbar {
    background-color: #fff !important;
    background-color: #fff!important;
    /* height: 100px!important; */
    border-bottom: 1px solid #e0e0e0!important;
    box-shadow: none!important;
    height: 60px;
}

.sticky {
}

.wrap_stricky {
}

.header_padding {
    height: 66px;
}

.cv_header_padding {
    height: 7vh;
}

@media(max-height:750px)  {
}