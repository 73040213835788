.navigation {
    margin-top: 20px;
    min-height: 45px;
}

.btn_add {
    margin-right: 10px;
}

.inputError {
    color: #dc3545;
}