.publication_body {
    padding-bottom: 50px;   
}

.publication_body a {
    text-decoration: none;
    color: black;
}


.publication_body h1 {
    margin-bottom: 30px;
}

.breadcrumb ol{
    background-color: white;
    padding: 30px 0px 20px 0px;
}

/* publications section */
.publication_card {
    border-radius: 20px;
    background-color: #F5F7FC;
}

.publication_card:hover {
    border-radius: 20px;
    background-color: #dde0e6;
    transition: 1s;
}

.publication_card_image img {
    width: 100%;
    height: 269px;
}


.publication_card_title {
    padding: 15px 18px 5px 18px;
    font-size: 20px;
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.publication_card_description {
    padding: 0px 18px 15px 18px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}