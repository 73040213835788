.curriculo_download {
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.90);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%);
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: 78vh;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 25px;
    overflow-y: scroll;
}

.component_title {
    margin-bottom: 10px;
}

.ad_row {
    width: 100%;
}

.buttons_row {
    width: 100%;
    margin-top: 35px;
}

.buttons_row button {
    margin-right: 25px;
    margin-bottom: 15px;
}

.component_title {
    font-size: 1.3rem;
}