/* Styles for Header component */
.navbar {
    background-color: #fff !important;
    background-color: #fff!important;
    /* height: 100px!important; */
    border-bottom: 1px solid #e0e0e0!important;
    box-shadow: none!important;
    height: 60px;
    
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: yellow;
    z-index: -1;
}

.wrap_stricky {
    position: relative;
    height: 42px;
}

.header_padding {
    height: 60px;
}

.cv_header_padding {
    height: 7vh;
}

@media(max-height:750px)  {
}

.register_button a {
    font-size: 16px !important;
    font-weight: 300 !important;
    width: 100px !important;
    height: 40px !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: 0.5s !important;
    color: #fff !important;
    background-color: #0C79E4 !important; 
    font-weight: 700;
}

.register_button a:hover{
    text-decoration: none;
    transition: 0.5s !important;
    background-color:  rgb(21, 137, 179) !important;
    -webkit-box-shadow: 0px 2px 20px -2px rgb(21, 137, 179) !important;
-moz-box-shadow: 0px 2px 20px -2px rgb(21, 137, 179) !important;
box-shadow: 0px 2px 20px -2px rgb(21, 137, 179) !important;
}

.login_button {
   padding-top: 10px !important;
   padding-bottom: 3px !important;

}

.login_button i {
    margin-top: -4px;
    margin-right: 2px;
 }

.login_navbar {
    padding-right: 0px !important;
}

.login_button a {
    font-size: 16px !important;
    width: 80px !important;
    height: 40px !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: 0.5s !important;
    color: #000;
}

.website_name {
   
}

.website_name img {
    margin-right: 10px;
    margin-top: -6px;
}

.website_name span {
}