.container_login {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input_label {
    margin-bottom: -2px;
}

.login_page {
    background: linear-gradient(to top,#d7c6a8,#b4f1ff,#97d3e8);
}

.card_title {
    text-align: center;
}


.error_text {
    color: #ef1a1a !important;
}

.senha_recovery {
    margin-top: 15px;
    text-align: center;
    color: #1a65ef;
    font-size: 15px;
}   

.senha_recovery:hover {
    cursor: pointer;
    text-decoration: underline;
}

.login_button {
    width: 100%;
    margin-top: 10px;
}

.register_button {
    width: 50%;
    margin-top: 10px;
    padding: 10px 5px;
    text-align: center;
}

.button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.input_login {
    height: 55px;
    border-radius: 5px;
}