.progress_container {

}

.progress_step {
    float:right;
}

.progress {
    background-color: #e9ecef;
}