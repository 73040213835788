::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.top_container {
    height: 100%;
    /* margin-top: 40px; */
}

.cv_content_row {
    height: 100% !important;
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-column-gap: 15px;
}

.cv_content_download {
    height: 100% !important;
    display: grid;
    grid-template-columns: 1fr;
}

@media (max-width: 990px) {
    .cv_content_row {
        height: 100% !important;
        display: grid;
        grid-template-columns: 1fr;
    }
}

.download_container {
    display: grid;
    width: 100%;
    padding-bottom: 30px;
}

.main_container {
    /* display: grid;
    grid-template-rows: 76px 82vh; */
    padding-bottom: 30px;
    grid-column-gap: 15px;
}

.second_container {
    display: grid;
    grid-template-rows: 76px 82vh;
    padding-bottom: 30px;
}

.second_container_view_cv {
    display: grid;
    grid-template-rows: 76px 30px 1fr;
    grid-row-gap: 10px;
    padding-bottom: 30px;
}


.title_page_curriculo {
    margin: 0.5rem 0rem 20px 0;
    margin-top: 1.5rem!important;
    display: block;
}

.badge_info {
    display: inline-block;
    margin-bottom: 25px;
    padding: 5px;
    font-size: 11px;
    color: #fff;
    background-color: #17a2b8;
    cursor: pointer;
    margin-right: 15px;
}

.badge_download {
    display: inline-block;
    margin-bottom: 5px;
    padding: 5px;
    font-size: 11px;
    color: #fff;
    background-color: #489649 !important;
    cursor: pointer;
    margin-left: 0;
}



.form_group {
    width: 100%;
}

.form_group_radio {
    width: 100%;
    margin-bottom: 0px;
}

.component {
    transition: box-shadow .25s;
    padding: 24px;
    margin: 0px 0rem 1rem 0;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.90);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%);
    padding-right: 5px;
    /* height: 100%; */
    /* display: grid;
    grid-template-rows: 37px auto 1fr 81px; */
}

.ad {
    margin: 10px 0;
}

.ad img {
    width: 100%;
    height: 100%;
}

.ad_fullwidth {
    margin: 10px 0;
}

.ad_fullwidth img {
    width: 300px;
    height: 300px;
}


.buttonSection {
    /* position: absolute;
    bottom: 30px; */
    padding-right: 18px;
    height: 60px;
}

.component .inputSection {
    /* height: 98%; */
    padding-right: 18px;
    padding-left: 3px !important;
    margin-top: 5px;
    /* overflow-y: scroll; */
}

@media(max-height:805px)  {
    .component {
        grid-template-rows: 37px auto 1fr 50px;
    }
    .main_container {
        grid-template-rows: 76px 72vh;
    }
} 




/* @media(max-height:1250px)  {

  
 }

@media(max-height:950px)  {

    .inputExperienceSection, .inputFomationSection, .inputOtherSection, .inputIdiomaSection {
        width: 100% !important;
        height: 72% !important;
        padding-right: 5px !important;
        padding-left: 3px !important;
    }

    .inputSection {
        height: 78% !important;
        max-height: 81% !important;
        padding-right: 18px !important;
        padding-left: 3px !important;
    }

    .component {
        height: 100%
    }
 }


@media(max-height:750px)  {
    .inputSection {
        height: 78% !important;
        max-height: 81% !important;
        padding-right: 18px !important;
        padding-left: 3px !important;
    }

    .component {
        margin-top: 10px;
        height: 65vh;
        max-height: 69vh;
    }


    .inputExperienceSection, .inputFomationSection, .inputOtherSection, .inputIdiomaSection {
        width: 100% !important;
        height: 65% !important;
        padding-right: 5px !important;
        padding-left: 3px !important;
    }
 }


 @media(max-height:650px)  {
    .inputExperienceSection, .inputFomationSection, .inputOtherSection, .inputIdiomaSection {
        height: 55% !important;
        padding-right: 18px !important;
        padding-left: 3px !important;
    }
 }

@media(max-height:540px)  {
    .inputSection {
        height: 75% !important;
        padding-right: 18px !important;
        padding-left: 3px !important;
    }

    .inputExperienceSection, .inputFomationSection, .inputOtherSection, .inputIdiomaSection {
        width: 100% !important;
        height: 50% !important;
        padding-right: 5px !important;
        padding-left: 3px !important;
    }

    .curriculo_preview {
        overflow-x: scroll;
    }
 } */



/* .component .inputSection {
    height: 98%;
    padding-right: 18px;
    padding-left: 3px !important;
    margin-top: 5px;
}



.inputOtherSection {
    width: 100%;
    height: 82%;
    padding-right: 5px;
    padding-left: 3px !important;
}

.inputIdiomaSection {
    width: 100%;
    height: 82%;
    padding-right: 5px;
    padding-left: 3px !important;
}

.inputFomationSection {
    width: 100%;
    height: 82%;
    padding-right: 5px;
    padding-left: 3px !important;
}

.inputExperienceSection {
    width: 100%;
    height: 82%;
    padding-right: 5px;
    padding-left: 3px !important;
}

.component form {
    height: 78%;
    display: block
} */

.component_title {
    font-size: 24px;
    margin-bottom: 0;
}

.btn_control {
    margin-top: 20px;

}

.btn_info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    right: 0;
}

.btn_info:hover {
    background-color: #148496;
    border-color: #148496;
}

.ad_container {
    width: 100%;
    min-width: 150px;
    height: 70px;
}

.ad_container p {
    height: 70px;
    margin-bottom: 5px;
}