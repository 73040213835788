/* style for home component */
.home_background {
    width: 100%;
    position: relative;
    background-size: cover;
    padding: 28px 0 40px 0;
    /* height: 480px; */
    background-color: #1E90FF;
    /* background: url('/images/new_background.png') center bottom no-repeat;*/
}

.title_text {
    font-family: sans-serif;
    font-size: 40px;
    color: white;
    margin-bottom: 16px;
}

.title_paragraph {
    color: white;
}

.home_info_col {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.home_it_works {
    background-color: white;
    text-align: center;
    margin-top: 60px;
    font-size: 3.8vmin;
    font-weight: 400;
    padding-bottom: 0px;
}

.call_to_action_row {
    margin: 100px 0px;
}

.call_to_action {
    width: 100%;
    margin-top: 60px;
    text-align: center;
    height: 150px;
}

.call_to_action_btn {
    background-color: rgb(23, 125, 160) !important;
    color: #fff !important;
    transition: 0.5s !important;
    border-radius: 50px !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    width: 200px !important;
    height: 55px !important;
    border:none;
}

.call_to_action_btn:hover {
    transition: 0.5s !important;
    background-color:  rgb(21, 137, 179) !important;
    -webkit-box-shadow: 0px 2px 20px -2px rgb(21, 137, 179) !important;
-moz-box-shadow: 0px 2px 20px -2px rgb(21, 137, 179) !important;
box-shadow: 0px 2px 20px -2px rgb(21, 137, 179) !important;
}

/* Feature card */

.feature_card {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 10px;
    padding: 20px; 
    padding-top: 35px;
}

.feature_card_title {
    font-family: sans-serif;
    font-size: 24px;
    margin-top: 15px;
}

.feature_card_description {
    font-family: sans-serif;
    font-size: 18px;
    margin-top: 15px;
    color: #A89595;
}

/* Platform Numbers */

.section_title {
    font-family: "Open Sans";
    font-size: 4vmin;
    margin-bottom: 55px;
    text-align: center;
}

.section_container {
    width: 100%;
    background-color: #F5F7FC;
    padding-bottom: 80px;
    padding-top: 80px;
}

.section_container a {
    text-decoration: none;
    color: black;
}

.number_card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.number_card_icon {
    margin-bottom: 15px;
}

.number_card_title {
    font-size: 26px;
}

.number_card_description {
    font-size: 16px;
}

/* Section models */
.section_models {
    background-color: white;
    width: 100%;
    padding-bottom: 50px;
    padding-top: 80px;
}

.model_card {
    width: 100%;
    height: 45vmin;
    display: flex;
    justify-content: center;
}

.model_card img {
    width: 80%;
    height: 100%;
}

.model_card_pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 70px 0;
}

.pagination_circle {
    margin-right: 15px;
    width: 32px;
    height: 32px;
    background-color:#397FBF; 
    border: 1px solid #eee;
    border-radius: 32px;
}

/* publications section */
.publication_card {
    border-radius: 20px;
    background-color: white;
}

.publication_card_image img {
    width: 100%;
    height: 269px;
}

.publication_card_title {
    padding: 15px 18px 5px 18px;
    font-size: 20px;
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.publication_card_description {
    padding: 0px 18px 15px 18px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}