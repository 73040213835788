.ad {
    margin: -2px 0;
}

.ad img {
    width: 100%;
    height: 100%;
}

.ad_fullwidth {
    width: 100%;    
    margin: -2px 0;
}

.ad_fullwidth img {
    width: 300px;
    height: 300px;
}

.span_ad {
    font-size: 12px;
}