.publication_body {
    padding-bottom: 50px;   
}

.breadcrumb ol{
    background-color: white;
    padding: 30px 0px 20px 0px;
}

.post_title {
    margin-bottom: 20px;
    text-align: left !important;
}

.publication_sub_title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 26px;
    text-align: left !important;
}

.publication_content {
    text-align: justify;
}

.div_sticky {
    position: sticky;
    top: 50px;
}