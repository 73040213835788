.nome_completo {
    font-size: 1.0em;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: -3px;
}

.second_infomation {
    font-size: 0.8em;
    display: inline;
}

.second_infomation p {
    margin-bottom: 0px;
    display: inline;
}

.address {
    font-size: 0.8em;
    margin-top: -3px;
}

.address p {
    margin-bottom: 0px;
}

.contact_details {
    margin-top: -3px;
}

.contact_details p {
    font-size: 0.8em;
}

.objectives {
    margin-top: 10px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

.objectives h1 {
 font-size: 1.0em;
 font-weight: bold;
}

.objectives p {
    line-height: 16px;
    font-size: 0.8em;
}

.highlight {
    background-color: #f8ffb4;
    transition: all 0.3s ease-in-out;
    word-wrap: break-word;
}

.header_top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section_division {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}


.cv_section {
    margin-top: 10px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 1.0em;
}

.experience_main_information {
    margin-top: 20px;
}

.cv_section h1 {
 font-size: 1.0em;
 font-weight: bold;
}

.cv_section p {
    line-height: 16px;
    font-size: 0.8em;
    margin-bottom: 0px;
}

.experience_period .period {
    margin-top: -10px;
}

.descricao_atividades {
    margin-top: 10px;
}