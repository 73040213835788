.curriculo_preview {
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.90);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%);
    margin-bottom: 30px;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    padding-top: 10px;
    overflow-y: scroll;
}
