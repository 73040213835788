body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, body #root {
  min-height: 100vh;
  font-family: "Open Sans";
}

#root {
  display: grid;
  grid-template-rows: 59px 1fr;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 



@media(max-height:810px)  {
    
  body, body #root {
      min-height: 85vh;
  }

} */