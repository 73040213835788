.modalList {
    padding-left: 40px; 
}

.inputError {
    color: #dc3545;
}

.radioCheckError {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.displayNone {
    display: none;
}